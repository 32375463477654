import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'auth',
        component: () => import(/* webpackChunkName: "auth" */ '../views/Auth.vue'),
        meta: {
            title: 'Авторизация'
        }
    },
    {
        path: '/partners',
        name: 'partners',
        component: () => import(/* webpackChunkName: "partners" */ '../views/Partners.vue'),
        meta: {
            title: 'Партнеры'
        }
    },
    {
        path: '/partners/:page',
        name: 'partnersPage',
        component: () => import(/* webpackChunkName: "partners" */ '../views/Partners.vue'),
        meta: {
            title: 'Партнеры'
        }
    },
    {
        path: '/partner/:projectId/:partnerId',
        name: 'partner',
        component: () => import(/* webpackChunkName: "partner" */ '../views/Partner.vue'),
        meta: {
            title: 'Партнер'
        }
    },
    {
        path: '/add-partner',
        name: 'addPartner',
        component: () => import(/* webpackChunkName: "addPartner" */ '../views/AddPartner.vue'),
        meta: {
            title: 'Добавить партнера'
        }
    },
    {
        path: '/statistics',
        name: 'statistics',
        component: () => import(/* webpackChunkName: "statistics" */ '../views/Statistics.vue'),
        meta: {
            title: 'Статистика'
        }
    },
    {
        path: '/webmasters',
        name: 'webmasters',
        component: () => import(/* webpackChunkName: "webmasters" */ '../views/Webmasters.vue'),
        meta: {
            title: 'Эффективность веб-мастеров'
        }
    },
    {
        path: '/utm',
        name: 'utm',
        component: () => import(/* webpackChunkName: "utm" */ '../views/UTMCampaign.vue'),
        meta: {
            title: 'UTM-метки'
        }
    },
    {
        path: '/events',
        name: 'events',
        component: () => import(/* webpackChunkName: "events" */ '../views/Events.vue'),
        meta: {
            title: 'Отчет по событиям'
        }
    },
    {
        path: '/postback',
        name: 'postback',
        component: () => import(/* webpackChunkName: "postback" */ '../views/Postback.vue'),
        meta: {
            title: 'Постбэк'
        }
    },
    {
        path: '/manage-costs',
        name: 'manage-costs',
        component: () => import(/* webpackChunkName: "manage-costs" */ '../views/ManageCosts.vue'),
        meta: {
            title: 'Управление расходами'
        }
    }
];

const router = new VueRouter({
  routes
});

export {
    routes,
    router
};
