



























































import Vue from 'vue';

import RCP from '@/api/RCP.ts';

import swal from 'sweetalert2';

import { User } from '@/types/User';
import { ErrorResponse } from '@/types/Errors';

export default Vue.extend({
    name: 'mainMenu',
    data() {
        return {
            openMenu: false
        };
    },
    computed: {
        user(): User {
            return this.$store.state.user.info;
        }
    },
    watch: {
        $route() {
            this.openMenu = false;
        }
    },
    methods: {
        switchMenu() {
            this.openMenu = !this.openMenu;
        },
        logout() {
            RCP({
                method: 'Auth.logout'
            }).then(() => {
                this.$store.commit('user/clear');
                this.$router.push('/');
            }).catch((error: ErrorResponse) => {
                if (error.show) {
                    swal.fire({
                        icon: 'error',
                        title: 'Ошибка',
                        text: error.message,
                        confirmButtonText: 'Ок',
                        confirmButtonColor: '#605ab9'
                    });
                }
            });
        }
    }
});
