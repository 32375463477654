export default {
    state: {
        projects: []
    },
    mutations: {
        set(state: any, projects: any) {
            state.projects = projects;
        }
    },
    namespaced: true
};
