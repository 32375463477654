










































import Vue from 'vue';
import Field from '@/components/Field.vue';

import RCP from '@/api/RCP.ts';

import Validator from '@/helpers/validator';

import { User } from '@/types/User';
import { ErrorResponse } from '@/types/Errors';

export default Vue.extend({
    name: 'authWindow',
    props: {
        background: {
            type: Boolean,
            default: false
        },
        title: {
            type: String,
            default: 'Авторизация'
        },
        description: {
            type: String,
            default: ''
        }
    },
    mounted() {
        this.$nextTick().then(() => {
            this.$forceUpdate();
        });
    },
    data() {
        return {
            form: {
                login: '',
                password: ''
            },
            formRules: {
                login: 'required',
                password: 'required'
            },
            errors: {
                login: '',
                password: ''
            },
            formRequest: false,
            request: false,
            error: ''
        };
    },
    created() {
        if (this.user.login) {
            this.form.login = this.user.login;
        }
    },
    computed: {
        user(): User {
            return this.$store.state.user.info;
        }
    },
    methods: {
        validate(submit = true) {
            if (!this.request) {
                const validate = new Validator(this.form, this.formRules).make();

                this.errors = validate.errors;

                if (validate.isValid && submit) {
                    this.submit();
                } else {
                    this.formRequest = true;
                }
            }
        },
        submit() {
            this.request = true;
            this.error = '';

            RCP({
                method: 'Auth.login',
                params: this.form,
                id: 'AuthWindowLogin'
            }).then((result: any) => {
                if (result.id) {
                    return this.getProjects().then(() => {
                        this.$store.commit('user/closeAuth');
                        this.$store.commit('user/set', result);
                        if (this.$route.path === '/') {
                            this.$router.push('/statistics');
                        }
                    });
                }

                this.error = 'Ошибка';
                this.request = false;
            }).catch((error: ErrorResponse) => {
                this.error = error.message;

                this.request = false;
            });
        },
        getProjects() {
            return new Promise((resolve: any, reject: any) => {
                RCP({
                    method: 'Filter.pp',
                    id: 'getFilters'
                }).then((result: any) => {
                    this.$store.commit('filters/set', result);
                    resolve();
                }).catch((error: ErrorResponse) => {
                    console.warn(error);
                    reject();
                });
            });
        }
    },
    watch: {
        formRequest(val) {
            if (val) {
                this.$watch('form', () => {
                    this.validate(false);
                }, {
                    deep: true
                });
            }
        }
    },
    components: {
        Field
    }
});
