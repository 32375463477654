export default {
    state: {
        lastId: 0
    },
    mutations: {
        updateId(state: any, user: any) {
            state.lastId++;
        }
    },
    namespaced: true
};
